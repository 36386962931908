<template>
  <div v-if="items.length > 0">
    <v-alert
      dense
      class="ma-0 rounded-t-0 text-body-2"
      type="info"
      text
      v-if="settings.assessorNow"
      :prominent="!$vuetify.breakpoint.xs"
    >
      Zweitbeurteilungen können bis
      <DateValue :value="settings.assessorEndDate" long />,
      {{ settings.assessorEndTime }} eingetragen werden
    </v-alert>
    <v-alert
      dense
      class="ma-0 rounded-t-0 text-body-2"
      type="info"
      text
      v-if="settings.titleNow"
      :prominent="!$vuetify.breakpoint.xs"
    >
      Du kannst die Titel der von dir betreuten Arbeiten bis am
      <DateValue :value="settings.titleEndDate" long />,
      {{ settings.titleEndTime }} Uhr anpassen
    </v-alert>
    <v-card flat class="rounded-t-0" :loading="loading">
      <v-list subheader dense>
        <template v-if="itemsCoach.length > 0">
          <v-system-bar>Betreuung</v-system-bar>
          <PersonItemBasic
            :to="{ name: 'ThesisTitleEdit', params: { id: item.id } }"
            v-for="item in itemsCoach"
            :key="'ma' + item.id"
            :value="{ ...item.student, description: item.title }"
          ></PersonItemBasic>
        </template>
        <template v-if="itemsAssessor.length > 0">
          <v-system-bar>Zweitbeurteilung</v-system-bar>
          <PersonItemBasic
            :to="{ name: 'Thesis' }"
            v-for="item in itemsAssessor"
            :key="'ma' + item.id"
            :value="{ ...item.student, description: item.title }"
          />
        </template>
      </v-list>
    </v-card>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
export default defineComponent({
  name: "ThesisModule",
  components: { DateValue, PersonItemBasic },
  data() {
    return {
      loading: false,
      items: [],
      settings: {},
    };
  },
  computed: {
    itemsCoach() {
      return this.items.filter(
        (item) =>
          (item.firstCoach && this.$_isPerson(item.firstCoach)) ||
          (item.secondCoach && this.$_isPerson(item.secondCoach))
      );
    },
    itemsAssessor() {
      return this.items.filter(
        (item) => item.assessor && this.$_isPerson(item.assessor)
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.settings = await this.apiList({
        resource: "thesis/settings",
      });

      this.items = await this.apiList({
        resource: "thesis/thesis",
        query: `teacher=${this.$_profilePerson.id}`,
      });

      this.loading = false;
      if (this.items.length > 0) {
        this.$emit("loaded");
      }
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
